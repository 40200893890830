:root {
  --color-black: #000;
  --color-gray-concrete: #e6ebe4;
  --color-gray-sand: #f3f4ef;
  --color-green-deep: #11271a;
  --color-green-forrest: #044318;
  --color-green-lime: #bfff3f;
  --color-orange: #ff8a00;
  --color-red: #f96113;
  --color-white: #fff;
  --color-transparent: rgba(255, 255, 255, 0);

  --color-gray-sand-60: rgba(243, 244, 239, 0.6);
  --color-green-deep-60: rgba(17, 39, 26, 0.6);
  --color-orange-60: rgba(255, 138, 0, 0.6);
  --color-red-60: rgba(249, 97, 19, 0.6);
}

:export {
  --color-gray-concrete: #e6ebe4;
  --color-gray-sand: #f3f4ef;
  --color-green-deep: #11271a;
  --color-green-forrest: #044318;
  --color-green-lime: #bfff3f;
  --color-orange: #ff8a00;
  --color-red: #f96113;
  --color-white: #fff;
  --color-transparent: rgba(255, 255, 255, 0);
}
