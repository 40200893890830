:root {
  --font-family-base: 'Sansation', sans-serif;
  --font-weight-base: 400;
  --font-weight-bold: 700;

  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-30: 30px;
  --font-size-34: 34px;
  --font-size-40: 40px;

  --font-size-unit: 1px;
  --line-height-text: 1.33;
  --line-height-title: 1.1;
  --font-size-viewport-min: 320;
  --font-size-viewport-max: 1440;

  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-12-16: clamp(12px, calc(12px + 4 * var(--m)), 16px);
  --font-size-14-16: clamp(14px, calc(14px + 2 * var(--m)), 16px);
  --font-size-16-20: clamp(16px, calc(16px + 4 * var(--m)), 20px);
  --font-size-16-25: clamp(16px, calc(16px + 9 * var(--m)), 25px);
  --font-size-20-24: clamp(20px, calc(20px + 4 * var(--m)), 24px);
  --font-size-20-30: clamp(20px, calc(20px + 10 * var(--m)), 30px);
  --font-size-24-40: clamp(24px, calc(24px + 16 * var(--m)), 40px);
  --font-size-30-60: clamp(30px, calc(30px + 30 * var(--m)), 60px);
  --font-size-34-80: clamp(34px, calc(34px + 46 * var(--m)), 80px);
}
