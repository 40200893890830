:root {
  --size-5: 5px;
  --size-10: 10px;
  --size-15: 15px;
  --size-20: 20px;
  --size-25: 25px;
  --size-30: 30px;
  --size-40: 40px;
  --size-50: 50px;
  --size-60: 60px;
  --size-70: 70px;
  --size-80: 80px;
  --size-90: 90px;
  --size-100: 100px;

  --gutter: 5vw;
  --gutter-responsive: max(5vw, var(--size-20));
  --site-header-mobile-height: var(--size-60);
  --site-header-desktop-height: var(--size-70);

  --size-container-max-width-sm: 840px;
  --size-container-max-width-md: 1180px;
  --size-container-max-width-lg: 1440px;
}
